<template>
  <template v-if="isMismatchingLocale && link && !wasCloseBefore">
    <ModalModule
      v-model="showModal"
      :title="dict['localeVersionOverlay.title.label']"
      :close-icon-text="dict['localeVersionOverlay.closeIcon.label']"
      :click-to-close="false"
      :esc-to-close="true"
      @close="handleClose"
      @abort="handleClose"
    >
      <p class="mb-4">
        {{ dict['localeVersionOverlay.intro.message'] }}
      </p>
      <LinkElement
        :link="link"
        style-type="attraction"
        class="me-2 flex-column gap-1 locale-version-overlay-button mb-4 mb-md-0"
        @click="handleConfirm"
      >
        {{ dict['localeVersionOverlay.confirmText.label'] }}
        <span :class="$style.small">{{ targetCountryLabel }}</span>
      </LinkElement>
      <Button
        style-type="primary"
        class="flex-column gap-1 locale-version-overlay-button"
        @click="handleClose"
      >
        {{ dict['localeVersionOverlay.abortText.label'] }}
        <!--<span :class="$style.small">{{ currentCountryLabel }}</span>-->
      </Button>
      <hr class="mt-4" />
      <LinkElement link="javascript:;" @click.prevent="handleDoNotAskAgain">
        {{ dict['localeVersionOverlay.doNotAskAgain.label'] }}
      </LinkElement>
    </ModalModule>
  </template>
</template>
<script lang="ts" setup>
import {
  ModalModule,
  Button,
  LinkElement
} from '@hypercodestudio/basler-components';
import { getCountryLanguageLabel } from '~/utils/getCountryLanguageLabel';
import type { LOCALE_CODE } from '~/lib/ContentfulService';
import type { LocaleVersionOverlayResponseModel } from '~/server/api/contentful/localeVersionOverlay.get';
import { useGeoinformationStore } from '~/stores/geoinformationStore/geoinformationStore';
import { isDefined } from '~/utils/guards/isDefined';

interface Props {
  displayLanguage: string;
  detectedLocale?: string | null;
}

const LOCALE_VERSION_OVERLAY_CLOSED_KEY = 'locale-version-overlay';
const QUERY_PARAM_AUTOREDIRECT = 'autoRedirect';
const QUERY_PARAM_AUTOREDIRECT_VALUE = '1';

const props = defineProps<Props>();
const store = useGeoinformationStore();
const route = useRoute();

const wasCloseBefore = process.server
  ? true // never render on server
  : window.localStorage.getItem(LOCALE_VERSION_OVERLAY_CLOSED_KEY) === 'closed';

const logger = useLogger();
const { $localeSlugMap, $resolvedLocale } = useNuxtApp();
const showModal = ref(!wasCloseBefore);
const isMismatchingLocale = computed(
  () => props.detectedLocale !== $resolvedLocale.value
);

const dict = ref<Record<string, string>>({});
if (isMismatchingLocale.value) {
  dict.value = await $fetch<LocaleVersionOverlayResponseModel>(
    '/api/contentful/localeVersionOverlay',
    {
      query: {
        locale: props.displayLanguage
      }
    }
  );
}

const link = computed(() => {
  if (!props.detectedLocale) {
    return;
  }
  const localeUrl = $localeSlugMap.value[props.detectedLocale as LOCALE_CODE];
  if (!localeUrl) {
    return;
  }

  return buildUrlString(
    props.detectedLocale,
    localeUrl,
    undefined,
    undefined,
    // must be an absolute url for now as most external components are not
    // reactive (e.g. links in main navigation).
    // @see https://gcp.baslerweb.com/jira/browse/WEB2-2578
    true
  );
});
const currentCountryLabel = computed(() =>
  getCountryLanguageLabel(
    store.preferredCountry ?? $resolvedLocale.value,
    logger
  )
);
const targetCountryLabel = computed(() =>
  getCountryLanguageLabel(props.detectedLocale, logger)
);

function close() {
  showModal.value = false;
}

function saveClosedState() {
  window.localStorage.setItem(LOCALE_VERSION_OVERLAY_CLOSED_KEY, 'closed');
}

function handleDoNotAskAgain() {
  close();
  saveClosedState();
}

function handleClose() {
  close();
}

function handleConfirm() {
  if (store.preferredCountry && props.detectedLocale) {
    store.updatePreferredSettings({
      locale: props.detectedLocale
    });
  }

  close();
}

// Make redirect automatically in case query parameter "autoRedirect=1" is set
// @see https://gcp.baslerweb.com/jira/browse/DBP-759
watch(
  () => route.query?.autoRedirect,
  () => {
    if (
      QUERY_PARAM_AUTOREDIRECT in route.query &&
      route.query.autoRedirect === QUERY_PARAM_AUTOREDIRECT_VALUE &&
      isDefined(link.value)
    ) {
      showModal.value = false;
      route.fullPath;
      let queryParams = window.location.search
        .replace(
          `?${QUERY_PARAM_AUTOREDIRECT}=${QUERY_PARAM_AUTOREDIRECT_VALUE}`,
          ''
        )
        .replace(
          `&${QUERY_PARAM_AUTOREDIRECT}=${QUERY_PARAM_AUTOREDIRECT_VALUE}`,
          ''
        );
      if (queryParams.indexOf('?') === -1) {
        queryParams = '?' + queryParams.substring(1);
      }

      window.location.href = link.value + queryParams;
    }
  },
  { immediate: true }
);
</script>

<style module>
/*
can not reuse fs-6 as this is overwritten by hypercode component
with the same size as 4 and 5.
 */
.small {
  font-size: 0.75rem;
}
</style>

<style>
@media (max-width: 575.98px) {
  .locale-version-overlay-button {
    width: 100% !important;
  }
}
</style>
